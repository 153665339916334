import styled from 'styled-components';

import { Text } from 'common/typography';
import { media } from 'styles/utils';

export const MembershipHeaderContainer = styled.div`
  width: 100%;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet`
    padding-bottom: 0px;
  `}
`;

export const MembershipHeaderLabel = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: 4px 4px 0 0;
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  padding: 4px 8px;
  font-size: 10px;

  ${media.tablet`
    top: -1px;
    bottom: unset;
    border-radius: 0 0 4px 4px;
    font-size: 14px;
  `}
`;

export const MembershipHeaderTitle = styled(Text)`
  text-transform: uppercase;
  font-size: 12px;
  margin: 12px 0 4px;

  ${media.tablet`
    font-size: 30px;
    margin: 40px 0 16px 0;
  `}
`;

export const MembershipHeaderNormalPrice = styled(Text)`
  text-decoration: line-through;
  font-size: 12px;
  font-weight: 700;
  height: 22px;
  color: ${({ theme }) => theme.colors.gray.dark};

  ${media.tablet`
    font-size: 16px;
  `}
`;

export const MembershipHeaderPrice = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 6px;

  ${media.tablet`
    font-size: 64px;
    font-weight: 400;
    line-height: 80px;
  `}
`;

export const MembershipHeaderWeekText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 10px;

  ${media.tablet`
    font-size: 14px;
    margin: 12px 0 24px;
  `}
`;
