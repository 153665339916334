module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KS28N2G9RP"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TMRVQ2T","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"react-prime-gatsby","short_name":"Saints and stars","start_url":"/","background_color":"#fff","theme_color":"#2916BA","display":"standalone","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2cbb9584c0a1e068cd637290ef14b31b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"importWorkboxFrom":"local","runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","nl"],"localeJsonSourceName":"locales","defaultLanguage":"en","generateDefaultLanguagePage":true,"redirect":true,"siteUrl":"https://saints-stars.com","i18nextOptions":{"resources":{},"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/studios/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/studios","getLanguageFromPath":true},{"matchPath":"/:lang?/trainers/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/trainers","getLanguageFromPath":true},{"matchPath":"/:lang?/concepts/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/concepts","getLanguageFromPath":true},{"matchPath":"/:lang?/pricing","getLanguageFromPath":true},{"matchPath":"/:lang?/","getLanguageFromPath":true},{"matchPath":"/:lang?/:slug","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
