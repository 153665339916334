import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { formatPrice } from 'services';
import { useCheckoutContext } from 'modules/PricingPage';

import {
  MembershipHeaderLabel,
  MembershipHeaderTitle,
  MembershipHeaderNormalPrice,
  MembershipHeaderPrice,
  MembershipHeaderWeekText,
  MembershipHeaderContainer,
} from './styled';

export const MembershipHeader = ({ membership }: MembershipHeaderProps) => {
  const { t } = useTranslation();
  const { selectedMembershipType } = useCheckoutContext();
  const priceWithoutDiscount = selectedMembershipType === 'annually'
    ? membership.annualSerie?.normalPrice
    : membership.flexSerie?.normalPrice;

  const getPrice = () => {
    if (selectedMembershipType === 'upgrade' && membership.membershipIdUpgrade) {
      return membership.upgradeSerie?.price.amount;
    }

    if (selectedMembershipType === 'flex') {
      return membership.flexSerie?.price?.amount;
    }

    return membership.annualSerie?.price?.amount;
  };

  const getLabel = () => {
    let labelContent: string | undefined = '';

    if (selectedMembershipType === 'upgrade') {
      labelContent = membership.labelUpgrade;
    }

    if (selectedMembershipType === 'flex') {
      labelContent = membership.labelFlex;
    }

    if (selectedMembershipType === 'annually') {
      labelContent = membership.labelAnnually;
    }

    return labelContent && <MembershipHeaderLabel>{labelContent}</MembershipHeaderLabel>;
  };

  return (
    <MembershipHeaderContainer>
      {getLabel()}
      <MembershipHeaderTitle>{membership.title}</MembershipHeaderTitle>
      {(selectedMembershipType !== 'upgrade' || membership.membershipIdUpgrade) && (
        <>
          <MembershipHeaderNormalPrice>
            {priceWithoutDiscount ? `€${priceWithoutDiscount}` : ''}
          </MembershipHeaderNormalPrice>
          <MembershipHeaderPrice>
            {selectedMembershipType === 'upgrade' && membership.membershipIdUpgrade ? '+' : ''}
            {formatPrice(getPrice() || 0)}
          </MembershipHeaderPrice>
          <MembershipHeaderWeekText>{t('every 4 weeks')}</MembershipHeaderWeekText>
        </>
      )}
    </MembershipHeaderContainer>
  );
};

type MembershipHeaderProps = {
  membership: i.ComponentMembershipBlock;
};
